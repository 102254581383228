<template>
    <div>
        <agile @after-change="event => currentSlide = event.currentSlide">
            <img class="slide" src="https://images.unsplash.com/photo-1506260408121-e353d10b87c7?ixlib=rb-1.2.1&amp;q=85&amp;fm=jpg&amp;crop=entropy&amp;cs=srgb&amp;w=1600&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjE0NTg5fQ"/>
            <img class="slide" src="https://images.unsplash.com/photo-1523712999610-f77fbcfc3843?ixlib=rb-1.2.1&amp;q=85&amp;fm=jpg&amp;crop=entropy&amp;cs=srgb&amp;w=1600&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjE0NTg5fQ"/>
            <img class="slide" src="https://images.unsplash.com/photo-1524260855046-f743b3cdad07?ixlib=rb-1.2.1&amp;q=85&amp;fm=jpg&amp;crop=entropy&amp;cs=srgb&amp;w=1600&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjE0NTg5fQ"/>

            <template slot="prevButton">
                <i class="mdi mdi-chevron-left"></i>
            </template>

            <template slot="nextButton">
                <i class="mdi mdi-chevron-right"></i>
            </template>

            <template slot="caption">
                <div>
                    {{ captions[currentSlide] }}
                </div>
            </template>
        </agile>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                currentSlide: 0,
                captions: [
                    'This is slide 1',
                    'This is the second slide',
                    'This is a third and final slide',
                ]
            }
        }
    }
</script>

<style>
    .agile__nav-button {
        background: transparent;
        border: none;
        color: #fff;
        cursor: pointer;
        font-size: 36px;
        height: 100%;
        position: absolute;
        top: 0;
        transition-duration: 0.3s;
        width: 80px;
    }

    .agile__nav-button--prev {
        left: 0;
    }

    .agile__nav-button--next {
        right: 0;
    }

    .agile__dots {
        bottom: 10px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

    .agile__dot {
        margin: 0 10px;
    }

    .agile__dot button {
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        transition-duration: 0.3s;
        width: 10px;
    }

    .agile__dot--current button, .agile__dot:hover button {
        background-color: #fff;
    }

    .slide {
        display: block;
        height: 500px;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
    }

    .agile__caption {
        position: absolute;
        display: flex;
        align-items: flex-end;
        bottom: 0;
        left: 0;
        color: #fff;
        background: linear-gradient(0deg, rgb(0 0 0 / 100%) 00%, rgba(0,0,0,0) 100%);
        width: 100%;
        padding: 30px 30px 30px 30px;
        height: 164px;
    }
</style>
